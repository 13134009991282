import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=2889865e&"
import script from "./Modal.vue?vue&type=script&lang=ts&"
export * from "./Modal.vue?vue&type=script&lang=ts&"
import style0 from "./Modal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonCircle: require('/tmp/build_e115b7cb/src/components/button/Circle.vue').default})
